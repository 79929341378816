import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { Button } from 'components/buttonV2';
import { ApplicationPrimaryAction } from 'features/applications/components/applicationCard/applicationPrimaryAction';
import { ApplicationStatus } from 'features/applications/components/applicationCard/applicationStatus';
import { Status } from 'features/applications/types/applicationsTypes';
import { useNavContext } from 'features/nav/context/navContext';
import { useGetOnboardingEnabledConfig } from 'features/onboarding/hooks';
import tracking from 'utils/tracking';

import { isReadyForOndato } from '../../hooks/useCurrentFlowStep';

import StepCard from './stepCard';
import { StepCardText } from './stepCard.styles';

type Steps = 'requestForFinancing' | 'acceptOffer';

type DrawerProps = {
  id?: string;
  applicationId?: string;
  offerId?: string;
  status?: Status;
  drawer: 'applicationDetails' | 'offerDetails';
};

type Props = {
  id?: string;
  applicationId?: string;
  offerId?: string;
  status?: Status;
  step: Steps;
  disabled?: boolean;
  loading?: boolean;
  onInactiveClick?: () => unknown;
  openBankAccountDrawer?: (visible: boolean) => void | undefined;
};

const statusesOrder: Status[] = [
  'NA',
  'NOT_FILLED',
  'INITIAL',
  'SUBMITTED_FOR_VOTING',
  'ADDITIONAL_INFORMATION_NEEDED',
  'ADDITIONAL_INFO_SUBMITTED',
  'ASSESSMENT_ONGOING',
  'OFFER_SENT',
  'OFFER_REJECTED',
  'OFFER_ACCEPTED',
  'KYC_WAITING',
  'KYC_COMPLETED',
  'KYC_NOT_REQUIRED',
  'KYC_REJECTED',
  'CONTRACT_PREPARED',
  'CONTRACT_PREPARATION_IN_PROGRESS',
  'CONTRACT_PREPARATION_FAILED',
  'CONTRACT_SIGNED'
];

const offerAvailableStatuses = [
  'OFFER_ACCEPTED',
  'KYC_WAITING',
  'KYC_COMPLETED',
  'KYC_NOT_REQUIRED',
  'CONTRACT_PREPARED',
  'CONTRACT_PREPARATION_IN_PROGRESS',
  'CONTRACT_PREPARATION_FAILED',
  'CONTRACT_SIGNED'
];

const isStepEnabled = (currentStep: Steps, status: Status, completedFromStatus: Status) => {
  switch (currentStep) {
    case 'requestForFinancing':
      return true;

    case 'acceptOffer':
      return statusesOrder.indexOf(status) >= statusesOrder.indexOf(completedFromStatus);
  }
};

export const isStepCompleted = (step: Steps, status: Status, completedFromStatus: Status) => {
  switch (step) {
    case 'requestForFinancing': {
      return statusesOrder.indexOf(status) >= statusesOrder.indexOf(completedFromStatus);
    }
    case 'acceptOffer':
      return statusesOrder.indexOf(status) >= statusesOrder.indexOf('OFFER_ACCEPTED');
  }
};

const PrimaryAction = ({ step, status, ...ids }: Props) => {
  if (step === 'acceptOffer' && status === 'OFFER_SENT') {
    return <ApplicationPrimaryAction {...ids} />;
  }

  if (step === 'requestForFinancing') {
    return <ApplicationPrimaryAction {...ids} />;
  }

  return null;
};

const Chip = ({ step, status, ...ids }: Props) => {
  const { t } = useTranslation();

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const stepCompleted = isStepCompleted(step, status ?? 'NOT_FILLED', ondatoAvailableCAPStatus);

  if (stepCompleted) {
    return <StatusChip color="green">{t('done')}</StatusChip>;
  }

  if (step === 'acceptOffer' && !stepCompleted && status !== 'OFFER_SENT') {
    return <StatusChip color="blue">{t('inProgress')}</StatusChip>;
  }

  if (step === 'acceptOffer' && status === 'OFFER_REJECTED') {
    return <ApplicationStatus {...ids} />;
  }

  if (step === 'requestForFinancing' && status !== 'NOT_FILLED') {
    return <ApplicationStatus {...ids} />;
  }

  return null;
};

const toastStatuses: Status[] = ['ASSESSMENT_ONGOING', 'SUBMITTED_FOR_VOTING'];

const CapStepCard = ({
  step,
  disabled,
  loading,
  status = 'NOT_FILLED',
  onInactiveClick,
  openBankAccountDrawer,
  ...ids
}: Props) => {
  const { t } = useTranslation();

  const { toggleRightSideDrawer } = useNavContext<DrawerProps>();

  const { isParallelFlowEnabled, ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const stepCompleted = isStepCompleted(step, status, ondatoAvailableCAPStatus);
  const stepEnabled = isStepEnabled(
    step,
    status,
    isParallelFlowEnabled ? ondatoAvailableCAPStatus : 'OFFER_SENT'
  );

  const showApplicationDetailsButton = step === 'requestForFinancing' && status !== 'NOT_FILLED';

  const viewOffer = offerAvailableStatuses.includes(status);

  const toastMessage =
    step === 'requestForFinancing' &&
    !stepCompleted &&
    !isParallelFlowEnabled &&
    toastStatuses.includes(status)
      ? t('offerSubmittedToastMessage')
      : undefined;

  const isStepDisabled = !stepEnabled || disabled || loading;

  const showMessage =
    isParallelFlowEnabled && step === 'acceptOffer' && status !== 'OFFER_SENT' && !stepCompleted;

  const ondatoReady = isReadyForOndato(status, ondatoAvailableCAPStatus);

  let title = t(step);

  if (step === 'acceptOffer') {
    title = t('getFinancingOffer');
  }

  return (
    <StepCard
      onInactiveClick={isStepDisabled ? onInactiveClick : undefined}
      step={step}
      loading={loading}
      disabled={isStepDisabled}
      title={title}
      StatusChip={<Chip step={step} status={status} {...ids} />}
      toastMessage={toastMessage}
      openBankAccountDrawer={openBankAccountDrawer}
    >
      {showMessage ? (
        <StepCardText>
          {t(ondatoReady ? 'waitForOfferExplanation' : 'offerSubmittedToastMessage')}
        </StepCardText>
      ) : null}
      {showApplicationDetailsButton && !viewOffer ? (
        <Button
          size="M"
          variant="Filled"
          color="LightBlack"
          onClick={() => toggleRightSideDrawer?.({ ...ids, drawer: 'applicationDetails', status })}
        >
          {t('applicationDetails')}
        </Button>
      ) : null}
      {stepCompleted ||
      status === 'OFFER_REJECTED' ||
      (step !== 'acceptOffer' && status === 'OFFER_SENT') ? null : (
        <PrimaryAction step={step} status={status} {...ids} />
      )}
      {step === 'acceptOffer' && viewOffer ? (
        <Button
          size="M"
          variant="Filled"
          color="LightBlack"
          onClick={() => {
            toggleRightSideDrawer?.({ ...ids, drawer: 'offerDetails' });
            tracking.setSubmitEvent('single_flow_offer_viewed');
          }}
        >
          {t('viewOffer')}
        </Button>
      ) : null}
      {step === 'requestForFinancing' && status === 'ADDITIONAL_INFORMATION_NEEDED' ? (
        <PrimaryAction step={step} status={status} {...ids} />
      ) : null}
    </StepCard>
  );
};

export default CapStepCard;
