import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Checkbox } from 'components';
import { Button } from 'components/buttonV2';
import { setToastMessage } from 'features/app/slice';
import { useSubmitApplicationMutation } from 'features/application/api';
import { DrawerImplementation } from 'features/application/drawers';
import { postMessages } from 'features/application/utils';
import ApplicationCard from 'features/applications/components/applicationCard/applicationCard';
import { selectIsEmbededInMobileWebview } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useAppSelector, useApplicationSelector, useAuthSelector } from 'hooks/redux/hooks';
import { Languages } from 'translations/translations';
import trackers, { ApplicationSubmit } from 'utils/tracking';

import { FormContainer } from '../../components/formContainer';
import { selectApplicationData, selectCapId, selectIsNewCompany } from '../../selectors';

import { ButtonSection, ConsentContainer, ReviewFormContainer } from './reviewForm.styles';

type ReviewFormProps = {
  onNextClick?: () => void;
};

const sleep = () => new Promise((resolve) => setTimeout(resolve, 200));

export const ReviewForm: FC<ReviewFormProps> = ({ onNextClick }) => {
  const { toggleRightSideDrawer, activeDrawer, showRightSideDrawer } =
    useNavContext<DrawerImplementation<'applicationReview'>>();

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const [submitApplication] = useSubmitApplicationMutation();

  const capId = useApplicationSelector<typeof selectCapId>(selectCapId);
  const isEmbeded = useAuthSelector<typeof selectIsEmbededInMobileWebview>(selectIsEmbededInMobileWebview);
  const isNewCompany = useAppSelector(selectIsNewCompany);
  const application = useApplicationSelector(selectApplicationData);

  const [confirmation, setConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      setIsBorderVisible(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-72px'
    });

    const target = containerRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  const onChange = (value: boolean) => {
    setConfirmation(value);
  };

  const onSubmitNavigate = async (submitted: boolean) => {
    if (isEmbeded) {
      postMessages.postNavigationFromCapEvent();
    }

    await sleep();

    const appendSuccessQuery = submitted ? '?success=true' : '';

    isNewCompany
      ? navigate(`/dashboard/verification${appendSuccessQuery}`)
      : navigate(`/dashboard/applications${appendSuccessQuery}`);
  };

  const onSubmit = (continueWithSubmit: boolean) => async () => {
    if (continueWithSubmit) {
      try {
        if (capId) {
          if (!isSubmitting) {
            setIsSubmitting(true);

            try {
              await submitApplication({ capId }).unwrap();
              const applicationData: ApplicationSubmit = {
                locality: i18n.language as Languages,
                product: application.product || '',
                subproduct: application.subProduct || '',
                enhanced_conversions: {
                  country: application.generalInfo?.company?.country || '',
                  phone_number: application.generalInfo?.applicant?.phoneNumber || '',
                  email: application.generalInfo?.applicant?.email || ''
                }
              };

              trackers.updateApplicationSubmitted(applicationData, { cap_product_type: application?.type });

              onNextClick?.();
              await onSubmitNavigate(true);
            } finally {
              setIsSubmitting(false);
            }
          }
        } else {
          dispatch(
            setToastMessage({ id: 'generalErrorMessage', status: 'error', message: 'generalErrorMessage' })
          );
        }
      } catch {
        // silently allow listeners to handle error
      }
    } else {
      await onSubmitNavigate(false);
    }
  };

  const handelCardClick = () => {
    toggleRightSideDrawer?.({
      drawer: 'applicationDetails',
      id: application.capId,
      drawerId: 'applicationReview',
      viewOnly: true,
      status: application.status
    });
  };

  return (
    <>
      <ReviewFormContainer>
        <FormContainer hiddenNextButton>
          <ApplicationCard
            viewOnly
            onCardClick={handelCardClick}
            key={application.capId ?? application.applicationId}
            id={application.capId}
            applicationId={application.applicationId}
            product={application.type}
            applicationNumber={application.applicationNumber}
            amount={application.requestedAmount}
            isActive={showRightSideDrawer && activeDrawer?.id === application.capId}
          />
        </FormContainer>
      </ReviewFormContainer>
      <div ref={containerRef} style={{ height: '1px' }} />
      <ButtonSection ref={buttonContainerRef} isBorderVisible={isBorderVisible}>
        <ConsentContainer>
          <Checkbox
            disabled={isSubmitting}
            name="confirm-data-validity"
            label={t('reviewApplicationConfirmCheck')}
            onChange={onChange}
            checked={confirmation}
          />
        </ConsentContainer>
        <Button
          color="Blue"
          variant="Filled"
          size="M"
          type="button"
          data-testid="button-confirm-n-submit"
          submitting={isSubmitting}
          disabled={!confirmation || isSubmitting}
          onClick={onSubmit(true)}
          media={{
            maxPhone: {
              size: 'L'
            }
          }}
        >
          {t('submit')}
        </Button>
      </ButtonSection>
    </>
  );
};
