import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import bin from 'assets/icons/bin.svg';
import download from 'assets/icons/download.svg';
import { DeleteConfirmationModal, DotsMenu } from 'components';
import { AsideMenuTopBar } from 'components/pageV2';
import { formatNumber } from 'components/text/formatters';
import { useDeleteApplicationMutation, useFetchOfferFilenamesQuery } from 'features/application/api';
import { cards } from 'features/application/forms/selectProduct';
import { selectApplicationData, selectPaymentDetails } from 'features/application/selectors';
import { DrawerInfo } from 'features/applications/components/applicationCard/applicationCard';
import { ApplicationStatus } from 'features/applications/components/applicationCard/applicationStatus';
import { useNavContext } from 'features/nav/context/navContext';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { ApplicationService } from 'services';
import { camelCase } from 'utils';

import {
  DeleteButton,
  DownloadLinkButton,
  DrawerTopContent,
  FileName,
  HeaderIcon,
  HeaderIconAndText,
  MainInfoSection,
  MainInfoSectionRow,
  MainSectionTitle,
  MainSectionValue,
  ProductName
} from './applicationDetailsDrawer.styles';

type Props = {
  viewOnly?: boolean;
};

export const ApplicationCardDrawerHero = ({ viewOnly }: Props) => {
  const { t, i18n } = useTranslation();

  const { activeDrawer, toggleRightSideDrawer } = useNavContext<DrawerInfo>();
  const { id: capId, applicationId, status } = activeDrawer || {};

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const application = useApplicationSelector<typeof selectApplicationData>(selectApplicationData);

  const [deleteApplication] = useDeleteApplicationMutation();

  const { data: offer } = useFetchOfferFilenamesQuery(
    {
      applicationId: applicationId ?? ''
    },
    { skip: !applicationId }
  );

  const { filenames, offerId } = offer ?? {};

  const icon = cards.find((card) => card.type === application?.type)?.iconToDisplay;

  const applicationStatus = status ?? 'INITIAL';
  const applicationType = application?.type ?? '';

  const handleClose = () => toggleRightSideDrawer?.();

  const getTruncatedFileName = (fileName?: string) => (fileName ?? '').replace(/(.*\/)*/, '');

  const handleDownload = (fileName?: string) => async () => {
    if (offerId && applicationId) {
      return ApplicationService.getOfferById(applicationId, offerId, getTruncatedFileName(fileName));
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDelete = () => {
    if (capId) {
      deleteApplication(capId);
      handleClose();
    }
  };

  const formatCurrency = formatNumber(i18n.language);

  const isInApplicationsListView = location.pathname.includes('/dashboard/applications');
  const paymentDetails = useApplicationSelector<typeof selectPaymentDetails>(selectPaymentDetails);

  return (
    <>
      <DeleteConfirmationModal
        label={t('confirmApplicationDeleteQuestion')}
        message={t('confirmApplicationDeleteQuestionExplanation')}
        mainActionLabel="delete"
        secondaryActionLabel="keepIt"
        isOpen={showDeleteConfirmationModal}
        onVisibilityChange={setShowDeleteConfirmationModal}
        onConfirmation={confirmDelete}
        icon={<Icons.BinWithBackground />}
        mainButtonColor="Red"
      />
      <AsideMenuTopBar
        handleClose={handleClose}
        topbarContent={
          applicationStatus === 'INITIAL' && !viewOnly ? (
            <DotsMenu>
              <DeleteButton color="Red" onClick={handleDelete} icons={{ left: bin }}>
                {t('delete')}
              </DeleteButton>
            </DotsMenu>
          ) : null
        }
      />
      <DrawerTopContent>
        <MainInfoSection>
          <HeaderIconAndText>
            <HeaderIcon>{icon}</HeaderIcon>
            {applicationType && (
              <ProductName>{t(camelCase(application?.type ?? '').toLowerCase())}</ProductName>
            )}
          </HeaderIconAndText>
          <MainInfoSectionRow>
            <MainInfoSection>
              <MainInfoSectionRow>
                <MainSectionTitle>{t('status')}</MainSectionTitle>
              </MainInfoSectionRow>
              {paymentDetails ? null : (
                <MainInfoSectionRow>
                  <MainSectionTitle>{t('amount')}</MainSectionTitle>
                </MainInfoSectionRow>
              )}
              {viewOnly ? null : (
                <MainInfoSectionRow>
                  <MainSectionTitle>{t('applicationId')}</MainSectionTitle>
                </MainInfoSectionRow>
              )}
            </MainInfoSection>
            <MainInfoSection>
              <MainInfoSectionRow>
                <ApplicationStatus id={capId} applicationId={applicationId} />
              </MainInfoSectionRow>
              {paymentDetails ? null : (
                <MainInfoSectionRow>
                  <MainSectionValue>
                    {formatCurrency(application?.generalInfo?.requestedAmount)}
                  </MainSectionValue>
                </MainInfoSectionRow>
              )}
              {viewOnly ? null : (
                <MainInfoSectionRow>
                  <MainSectionValue>{application?.applicationNumber ?? '-'}</MainSectionValue>
                </MainInfoSectionRow>
              )}
            </MainInfoSection>
          </MainInfoSectionRow>
          {isInApplicationsListView
            ? filenames?.map((filename) => (
                <MainInfoSectionRow key={filename}>
                  <DownloadLinkButton icons={{ left: download }} fileRequest={handleDownload(filename)}>
                    <FileName>{getTruncatedFileName(filename)}</FileName>
                  </DownloadLinkButton>
                </MainInfoSectionRow>
              ))
            : null}
        </MainInfoSection>
      </DrawerTopContent>
    </>
  );
};
